import credentials from '../localeCredentials/credentials'

interface FetchPropsFactory {
  props?: any
}

export interface UrlConfig<T> {
  queryParams?: T
  size?: number
  page?: number
}

export const getStoreFrontAuthHeaders = () => ({
  'X-Api-Key': credentials.apiKey,
  Secret: credentials.secret
})

export const fetchPropsFactory = (config?: FetchPropsFactory): RequestInit => {
  const headers = {
    'Content-Type': 'application/json',
    ...getStoreFrontAuthHeaders()
  }

  return {
    method: 'GET',
    headers,
    ...(config ? config.props : {})
  }
}

export const createEndpointError = (
  endpointName: string,
  error?: string | { status: string; error: string; messages?: string[] } | null
) => {
  const errStr =
    !!error && typeof error === 'object'
      ? `${error.status} ${error.error}. ${
          error.messages?.length ? `\n${error.messages.join('. ')}` : ''
        }`
      : error
  throw new Error(
    `An error on the ${endpointName} endpoint occurred: ${errStr ? `\n${errStr}` : ''}`
  )
}

export const createError = (
  error: string | { status: string; error: string; messages?: string[] } | null
) => {
  const errStr =
    !!error && typeof error === 'object'
      ? `${error.status} ${error.error}. ${
          error.messages?.length ? `\n${error.messages.join('. ')}` : ''
        }`
      : error
  return new Error(errStr ? `\n${errStr}` : '')
}

export const urlWithQueryParamsFactory = <T>(url: string, config?: UrlConfig<T>) => {
  let updatedUrl = ''
  let paramIndex = 0

  const size = config?.size || 48
  const page = config?.page || 1

  if (config?.queryParams) {
    for (const param in config.queryParams) {
      if (paramIndex === 0) {
        updatedUrl = `?${param}=${config.queryParams[param]}`
      } else {
        updatedUrl = `${updatedUrl}&${param}=${config.queryParams[param]}`
      }
      paramIndex++
    }
  }

  return `${url}${updatedUrl}${updatedUrl ? '&' : '?'}size=${size}&page=${page}`
}

export const httpImageFactory = (url: string, withHTTPS?: boolean) => {
  if (url === '') return url
  const re = /^(?:http|https):\/\//i
  const match = re.test(url)

  if (match) {
    return url
  }

  return `${withHTTPS ? 'https' : 'http'}://${url}`
}

export interface ApiCallResponse<T> {
  error: string | null
  data: T | null
}

export const apiCall = async <T>(url: string, props: RequestInit): Promise<ApiCallResponse<T>> => {
  let errorMsg = null
  let responseData = null
  let response

  try {
    response = await fetch(url, props)
    // When No content statusCode 204 appears, if we try to parse it like response.json() error parsing will come up
    if (response.status !== 204) {
      try {
        responseData = await response.json()
      } catch (e) {
        errorMsg = `PARSING RESPONSE ERROR: ${e as string}`
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-console --- We want to log the error
    console.log(e)
    errorMsg = 'API ERROR, CHECK LOGS TO DEBUG'
  }

  return {
    error: errorMsg,
    data: responseData
  }
}

type StoreFrontApiCall<T> = T | { status: string; error: string; messages?: string[] }

export const storefrontApiCall = async <T>(url: string, props: RequestInit) => {
  const { error, data } = await apiCall<StoreFrontApiCall<T>>(url, props)
  if (error || !data) {
    return {
      data: null,
      error
    }
  }

  // @ts-expect-error -- FIX
  if ('error' in data) {
    return {
      data: null,
      error: data
    }
  }

  return {
    data,
    error: null
  }
}
